import styled from 'styled-components';
import backgroundStripes from '../images/marketing-pro-hero-stripes.png';

const Container = styled.div`
  position: relative;
  background-position: top center;
  background-repeat: repeat-x;
  background-color: var(--darkest);

  .marketing-pro-hero--inner-container {
    background: url(${backgroundStripes});
    background-size: 1100px;
    padding: 348px 0px 100px 0px;
    max-width: 1296px;
    margin: 0px auto;
    background-position: top center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: relative;

    .wistia-video--wrapper {
      max-width: 100vw;
      display: block;

      .play-label {
        cursor: pointer;
        width: max-content;
        margin: 0 auto;
      }
    }

    .marketing-pro-hero--bottom-link {
      color: var(--white);
      text-align: center;
      margin-top: 15px;
      text-decoration: underline solid transparent 2px;
      transition: all 0.3s ease-in-out;
      &:hover {
        cursor: pointer;
        text-decoration: underline solid white 2px;
      }
    }

    @media (max-width: 1599px) {
      padding: 348px 20px 100px 20px;
      background-position-x: 225px;
      background-size: 1000px;
    }

    @media (max-width: 1199px) {
      padding-top: 260px;
      background-position-x: 200px;
      background-size: 900px;
    }

    @media (max-width: 991px) {
      padding: 260px 20px 50px 20px;
      flex-direction: column;
      background-position-x: 100px;
      background-size: 900px;
    }

    @media (max-width: 767px) {
      background-position-x: 90px;
      background-position-y: 60px;
      background-size: 650px;
    }

    @media (max-width: 575px) {
      padding-top: 280px;
      background-position-x: 55px;
      background-size: cover;
    }
  }

  .marketing-pro-hero--sub-title {
    font-size: var(--font-size-h5);
    margin: 1em 0px 80px;

    @media (max-width: 575px) {
      font-size: var(--font-size-h5);
    }
  }

  .marketing-pro-hero--copy-video-bottom {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
    @media (max-width: 575px) {
      max-width: 100%;
    }
  }

  .marketing-pro-hero--copy-video-right {
    text-align: left;
    width: calc(50% - 40px);

    form {
      justify-content: start;
    }

    @media (max-width: 991px) {
      text-align: center;
      width: 100%;
    }
  }

  .marketing-pro-hero--video-right {
    width: calc(50% - 40px);
    @media (max-width: 991px) {
      text-align: center;
      width: 100%;
    }
  }

  .marketing-pro-hero--image {
    position: absolute;
    right: 0;
    bottom: 0;
    /* display: none; */
  }

  span {
    text-decoration: underline;
    text-decoration-color: var(--titan-blue-3);
  }
`;

export { Container };
