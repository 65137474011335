import React, { useState } from 'react';
import HyperLink from 'components/Core/HyperLink';
import WebsiteImage from 'components/WebsiteImage';
import { ArrowRightIcon, ArrowRightHoverIcon } from 'components/Brand/Icons';
import Text from 'components/Core/Text';
import { Copy } from '../styles';

const FeatureCarouselCopy = ({ iconUrl, iconAlt, linkUrl, title, description }) => {
  const [cardHover, setCardHover] = useState(false);
  return (
    <HyperLink href={linkUrl}>
      <Copy onMouseEnter={() => setCardHover(true)} onMouseLeave={() => setCardHover(false)}>
        <div className="marketing-pro-row-content--icon">
          <WebsiteImage image={iconUrl} alt={iconAlt} />
        </div>
        <div>
          <Text type="h5" color="white" size="h4">
            {title}
          </Text>
          {description && (
            <Text type="p" color="white">
              {description}
            </Text>
          )}
        </div>
        <Text type="p" color="white" marginTop="50px">
          Learn more {!cardHover ? <ArrowRightIcon /> : <ArrowRightHoverIcon />}
        </Text>
      </Copy>
    </HyperLink>
  );
};

export default FeatureCarouselCopy;
