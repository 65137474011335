import React, { useState, useRef } from 'react';
import { Col, Row } from 'components/Core/Grid';
import useScrollPosition from 'hooks/useScrollPosition';
import useScrollDirection from 'hooks/useScrollDirection';
import ModalMarketingForm from 'components/Form/ModalMarketingForm';
import Button from 'components/Core/Button';
import MarketingProRowContentCarouselCopy from './MarketingProRowContentCarouselCopy';
import MarketingProRowContentCarouselImage from './MarketingProRowContentCarouselImage';

import { Container } from './styles';

const MarketingProRowContentCarousel = (props) => {
  const { data, inlineMarketingForm, imageRight } = props;
  const imageSectionRef = useRef(null);
  const refs = data.map(() => useRef(null));
  const [sectionIndex, setSectionIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, toggleIsModalOpen] = useState(false);
  const scrollDirection = useScrollDirection();

  useScrollPosition(
    ({ currPos }) => {
      const currentRef = refs[sectionIndex];

      if (currentRef) {
        let nextRef = refs[1];
        const lastRef = refs[refs.length - 1];
        let leavingPosition;
        const topOffset = 250;
        const elementHeight = 450;
        const scrollPosition = Math.abs(currPos.y - elementHeight);
        const startSection = refs[0]?.current?.getBoundingClientRect().top;
        const endSection = lastRef?.current?.getBoundingClientRect().top;
        const elementPosition =
          currentRef?.current?.getBoundingClientRect().top + document.documentElement.scrollTop;

        if (scrollDirection === 'down') {
          const isNotLastSection = sectionIndex !== refs.length - 1;
          if (isNotLastSection) nextRef = refs[sectionIndex + 1];
          const nextElementPosition =
            nextRef?.current?.getBoundingClientRect().top + document.documentElement.scrollTop;
          leavingPosition = Math.abs(nextElementPosition);
          if (startSection < topOffset && endSection > topOffset) {
            setIsVisible(true);
          }

          if (endSection < topOffset + 250) {
            setIsVisible(false);
          }

          if (scrollPosition > leavingPosition) {
            if (isNotLastSection) setSectionIndex(sectionIndex + 1);
          }
        } else if (scrollDirection === 'up') {
          const topOffsetUp = 0;
          if (sectionIndex !== 0) nextRef = refs[sectionIndex - 1];
          leavingPosition = Math.abs(elementPosition);

          if (endSection > topOffsetUp) {
            setIsVisible(true);
          }
          if (startSection > topOffsetUp) {
            setIsVisible(false);
          }

          if (scrollPosition < leavingPosition - 300) {
            if (sectionIndex !== 0) setSectionIndex(sectionIndex - 1);
          }
        }
      }
    },
    [sectionIndex, scrollDirection],
    null,
    false,
    100,
  );

  return (
    <Container isVisible={isVisible}>
      <Row gutter={40} className="marketing-pro-features-carousel--inner-container">
        <Col
          xs={24}
          sm={12}
          className="marketing-pro-features-carousel--image-section"
          ref={imageSectionRef}
        >
          {data.map((section, index) => {
            const lastSection = index === data.length - 1;
            const firstSection = index === 0;

            return (
              <MarketingProRowContentCarouselImage
                image={{ src: section.featuredImageUrl, alt: section.featuredImageAlt }}
                active={sectionIndex === index}
                isVisible={lastSection && isVisible}
                last={lastSection}
                first={firstSection}
                key={index}
              />
            );
          })}
        </Col>
        <Col xs={24} sm={7} className="marketing-pro-features-carousel--copy-container">
          {data.map((section, index) => {
            const ref = refs[index];

            return (
              <MarketingProRowContentCarouselCopy
                {...section}
                forwardedRef={ref}
                active={sectionIndex === index}
                first={index === 0}
                last={index === data.length - 1}
                isVisible={isVisible}
                key={index}
              />
            );
          })}
        </Col>
      </Row>
      <ModalMarketingForm
        isModalOpen={isModalOpen}
        onModalCancel={() => toggleIsModalOpen(false)}
        inlineMarketingForm={inlineMarketingForm?.onFormSubmissionInlineMarketingForm}
        onFormSuccess={() => toggleIsModalOpen(false)}
      />
    </Container>
  );
};

export default MarketingProRowContentCarousel;
