import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import HeroSubscribeForm from 'components/Form/HeroSubscribeForm';
import MarketingProHeroHTML5Video from './MarketingProHeroHTML5Video';
import Text from 'components/Core/Text';
import WistiaVideo from 'components/Core/WistiaVideo';
import BackgroundImage from 'components/Core/BackgroundImage';

import { Container } from './styles';

const MarketingProHero = ({ backgroundImg, form, title, description, splashPage, type, video }) => {
  const wistiaVideo = useStaticQuery(graphql`
    query {
      contentfulWistiaVideo(internalName: { eq: "Marketing Pro Demo" }) {
        ...WistiaVideo
      }
    }
  `).contentfulWistiaVideo;

  return (
    <Container>
      <BackgroundImage image={backgroundImg} loading="eager" />
      <div className="marketing-pro-hero--inner-container">
        <div
          className={`marketing-pro-hero--copy-${
            type === 'video-bottom' ? 'video-bottom' : 'video-right'
          }`}
        >
          <Text type="h1" className="marketing-pro-hero--title" color="white">
            {title}
          </Text>
          <Text
            type="p"
            color="white"
            marginBottom="80px"
            className="marketing-pro-hero--sub-title"
          >
            {description}
          </Text>
          {type === 'video-right' && (
            <div className="marketing-pro-hero--video-right max-lg">
              <WistiaVideo dataPosition="Hero" {...video} wrapperStyles={{ height: '50vw' }} />
            </div>
          )}

          <HeroSubscribeForm
            buttonLabel="Get Started"
            buttonType="secondary"
            inlineMarketingForm={form}
            className={`marketing-pro-hero--form-${type === 'video' ? 'video' : 'image'}`}
            notificationMessage=""
            allowMultiSubmissions={true}
            theme="light"
          />
          {splashPage && (
            <WistiaVideo
              {...wistiaVideo}
              wrapperStyles={{ width: '100%' }}
              width="100%"
              label={
                <Text type="p" className="marketing-pro-hero--bottom-link">
                  Take a Tour Now
                </Text>
              }
            />
          )}
        </div>
        {type === 'video-right' && (
          <div className="marketing-pro-hero--video-right min-lg">
            <WistiaVideo dataPosition="Hero" {...video} wrapperStyles={{ height: '380px' }} />
          </div>
        )}
      </div>
      {type === 'video-bottom' && <MarketingProHeroHTML5Video />}
    </Container>
  );
};

export default MarketingProHero;
