import React from 'react';
import Text from 'components/Core/Text';
import WebsiteImage from 'components/WebsiteImage';

const FeatureCopy = ({ title, description, quote, author, position, logo, logoAlt }) => {
  return (
    <div>
      <Text type="h3" color="white" size="h2">
        {title}
      </Text>
      <Text type="p" size="medium" color="white">
        {description}
      </Text>
      <span className="marketing-pro-feature-copy--hr" />
      <div className="marketing-pro-feature-copy--quote">
        <Text type="p" size="normal" color="white">
          "{quote}"
        </Text>
        <Text type="p" size="normal" color="white">
          <b>
            {author}
            {position && ','}
          </b>{' '}
          {position}
        </Text>
      </div>
      <WebsiteImage image={logo} alt={logoAlt} maxWidth="150px" />
    </div>
  );
};

export default FeatureCopy;
