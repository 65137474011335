import { graphql, useStaticQuery } from 'gatsby';

const useMarketingProOverviewData = () => {
  const {
    bottomForm,
    campaignBuildingMp4,
    directMailIcon,
    directMailImage,
    emailAutomationMp4,
    emailIcon,
    emailImage,
    hero,
    heroInlineMarketingForm,
    modalInlineMarketingForm,
    insightsImage,
    reputationIcon,
    reputationImage,
    adsImage,
    reviewSourcesMp4,
    seo,
    statsGraph,
    valuePropBackground,
  } = useStaticQuery(graphql`
    query {
      seo: contentfulSeo(contentful_id: { eq: "71lfXTG6Lmo9QS6LJMGrE8" }) {
        ...Seo
      }
      hero: contentfulDynamicHero(contentful_id: { eq: "2HfzRCuIt753iC3Z9odobf" }) {
        ...DynamicHero
      }
      heroInlineMarketingForm: contentfulInlineMarketingForm(
        contentful_id: { eq: "13t5Hn8kpNCotq4LLEA40p" }
      ) {
        ...InlineMarketingForm
      }
      bottomForm: contentfulRowContent(contentful_id: { eq: "4PQ2B7ZKmUQCag24iSiJzo" }) {
        ...RowContent
      }
      modalInlineMarketingForm: contentfulInlineMarketingForm(
        contentful_id: { eq: "7d31RYY7Gy0RCXYORqz5ll" }
      ) {
        ...InlineMarketingForm
      }
      valuePropBackground: contentfulImage(contentful_id: { eq: "7zChQJOgKus1kPQNuMicW8" }) {
        ...WebsiteImage
      }
      emailImage: contentfulImage(contentful_id: { eq: "4JvgnQaQRbWAJ0mlX0NVrg" }) {
        ...WebsiteImage
      }
      emailIcon: contentfulImage(contentful_id: { eq: "1pVP8p8guW9PUzrJFgfkmS" }) {
        ...WebsiteImage
      }
      directMailImage: contentfulImage(contentful_id: { eq: "6Os57ALrCQ9huKr5FwgaWG" }) {
        ...WebsiteImage
      }
      directMailIcon: contentfulImage(contentful_id: { eq: "1c05qKKtD4P1OKOoqcQKIk" }) {
        ...WebsiteImage
      }
      reputationImage: contentfulImage(contentful_id: { eq: "4Y5TB4Ygz2l0Se8omHWSqP" }) {
        ...WebsiteImage
      }
      reputationIcon: contentfulImage(contentful_id: { eq: "1xBkUczOKczdnHuntvMa2b" }) {
        ...WebsiteImage
      }
      adsImage: contentfulImage(contentful_id: { eq: "3wZxlAx9rCsfZSCao1Vw5Y" }) {
        ...WebsiteImage
      }
      insightsImage: contentfulImage(contentful_id: { eq: "3dc4RvViQ21pPd3bu8NcqO" }) {
        ...WebsiteImage
      }
      campaignBuildingMp4: contentfulImage(contentful_id: { eq: "1A613euvxO1KoQ80jlgblL" }) {
        ...WebsiteImage
      }
      reviewSourcesMp4: contentfulImage(contentful_id: { eq: "4x8vrRkVIoiAgO7SmiA3Mz" }) {
        ...WebsiteImage
      }
      emailAutomationMp4: contentfulImage(contentful_id: { eq: "4YDqn1PGkqkOon34VgzeWu" }) {
        ...WebsiteImage
      }
      statsGraph: contentfulImage(contentful_id: { eq: "o5LcCXLgG12UF8Nv6lasN" }) {
        ...WebsiteImage
      }
    }
  `);

  return {
    bottomForm,
    campaignBuildingMp4,
    directMailIcon,
    directMailImage,
    emailAutomationMp4,
    emailIcon,
    emailImage,
    hero,
    heroInlineMarketingForm,
    modalInlineMarketingForm,
    insightsImage,
    reputationIcon,
    reputationImage,
    adsImage,
    reviewSourcesMp4,
    seo,
    statsGraph,
    valuePropBackground,
  };
};

export default useMarketingProOverviewData;
