import styled from 'styled-components';

export const Copy = styled.div`
  background: ${(props) => props.backgroundUrl && `url(${props.backgroundUrl})`};

  .marketing-pro-row-content--icon {
    padding: 12px;
    border: 3px solid #02afff;
    border-radius: 50%;
    width: max-content;
    margin-bottom: 25px;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    stroke: white !important;
  }
`;

export const CopyContainer = styled.div`
  height: ${({ isMobile, last }) => {
    if (isMobile) {
      return '500px';
    } else if (!last) {
      return '100vh';
    } else {
      return '575px';
    }
  }};
  padding-top: 50px;
  display: flex;

  a {
    display: flex;
    align-self: start;
    height: max-content;
  }

  .marketing-pro-features-carousel--copy-section {
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear;
  }

  @media (max-width: 1199px) {
    padding-top: 80px;
  }

  @media (max-width: 575px) {
    .marketing-pro-features-carousel--copy-section.spacer {
      &:before {
        position: relative;
        content: '';
        height: ${(props) => {
          if (props.first) {
            return '550px';
          } else if (props.last) {
            return '200px';
          } else {
            return '400px';
          }
        }};
        display: block;
      }
    }
  }
`;
