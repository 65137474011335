import React from 'react';
import zoomDrainLogo from '../images/zoom-drain-logo.svg';
import mugyverLogo from '../images/mugyver-logo.svg';
import jupiterTequestaLogo from '../images/jupiter-tequesta-logo.svg';
import laHydroJetLogo from '../images/la-hydro-jet-logo.svg';
import tampaBayPlumbingLogo from '../images/tampa-bay-plumbing.svg';
import useMarketingProOverviewData from '../hooks/useMarketingProOverviewData';

export const heroContent = {
  title: 'Rethink how you do marketing',
  description:
    'ServiceTitan Marketing Pro is an all-in-one marketing automation solution for the trades. Leverage customer data and supercharge your contracting business with more calls, booked jobs and higher customer spend.',
};

export const featureCarouselContent = () => {
  const {
    directMailIcon,
    directMailImage,
    emailIcon,
    emailImage,
    reputationIcon,
    reputationImage,
    adsImage,
  } = useMarketingProOverviewData();

  return [
    {
      title: 'Email',
      description:
        'Send targeted emails using professionally designed templates. Automate emails based on ServiceTitan data. Measure true ROI for all your campaigns.',
      linkUrl: '/features/marketingpro/email',
      iconUrl: emailIcon.image,
      iconAlt: emailIcon.alt,
      featuredImageUrl: emailImage.image,
      featuredImageAlt: emailImage.alt,
    },
    {
      title: 'Direct Mail',
      description:
        'Set up automated direct mail campaigns in minutes to those most likely to engage. Tie booked jobs and revenue directly to each postcard.',
      linkUrl: '/features/marketingpro/direct-mail',
      iconUrl: directMailIcon.image,
      iconAlt: directMailIcon.alt,
      featuredImageUrl: directMailImage.image,
      featuredImageAlt: directMailImage.alt,
    },
    {
      title: 'Reputation',
      description:
        'Increase visibility and build trust with more quality reviews and consistent listings across 60+ sites. Match reviews to technicians and respond all from one place.',
      linkUrl: '/features/marketingpro/reputation',
      iconUrl: reputationIcon.image,
      iconAlt: reputationIcon.alt,
      featuredImageUrl: reputationImage.image,
      featuredImageAlt: reputationImage.alt,
    },
    {
      title: 'Ads',
      description:
        'Get visibility into true ROI on your Google ad campaigns and get more out of your marketing budget.',
      linkUrl: '/features/marketingpro/ads',
      iconUrl: reputationIcon.image,
      iconAlt: reputationIcon.alt,
      featuredImageUrl: adsImage.image,
      featuredImageAlt: adsImage.alt,
    },
  ];
};

export const overviewCopyImageSectionOne = () => {
  const { campaignBuildingMp4, emailAutomationMp4 } = useMarketingProOverviewData();

  return [
    {
      title: 'Target the right customer at the right time',
      description:
        'Create targeted audiences based on customer data like unsold estimates or expiring memberships. Targeted campaigns resonate better, drive more calls and bigger ROI.',
      quote:
        'I love that our customers are already in the system... it just makes it way easier to attempt to send a campaign vs the daunting task of having to export and import.',
      author: 'Christina Dear',

      position: 'Zoom Drain Philadelphia',
      logo: zoomDrainLogo,
      logoAlt: null,
      featuredVideo: campaignBuildingMp4?.image?.file?.url,
      colClassName: 'marketing-pro-overview--target-img',
      imageRight: true,
    },
    {
      title: 'Supercharge efficiency with campaign automation',
      description:
        'Automate your emails, direct mail and review requests and save time with pre-built templates. Respond quickly to reviews and manage listings from one, consolidated feed.',
      quote:
        "I love Marketing Pro -  the automated campaigns are great. They just keep bringing in business and I don't even have to think about them.",
      author: 'Megan Bedford',
      position: 'Mugyver Consulting',
      logo: mugyverLogo,
      logoAlt: null,
      featuredVideo: emailAutomationMp4?.image?.file?.url,
      imageRight: false,
    },
  ];
};

export const overviewCopyImageSectionTwo = (images) => {
  const { insightsImage, reviewSourcesMp4 } = useMarketingProOverviewData();

  return [
    {
      title: 'Make better business decisions with deeper insights',
      description:
        'See which marketing campaigns are actually driving true ROI for your company, or track which techs are generating the most positive reviews.',
      quote:
        'The ROI is definitely there. With our first few campaigns, we paid for a year, easily. We made over $4,000 in the first week of the first campaign.',
      author: 'Bill Highsmith',
      position: 'Jupiter-Tequesta Air Conditioning, Plumbing & Electric',
      logo: jupiterTequestaLogo,
      logoAlt: null,
      featuredImageUrl: insightsImage.image,
      featuredImageAlt: insightsImage.alt,
      imageRight: true,
    },
    {
      title: 'Deliver an exceptional customer experience',
      description:
        'Whether you send welcome emails, thank you postcards or respond quickly to customer reviews, Marketing Pro makes it easy to deliver an exceptional customer experience.',
      quote:
        'Our first Marketing Pro campaign was to notify our customers we were still open during city-wide COVID mandates. Within 2 minutes, we received 3,450 email replies thanking us for being open and supporting the community.',
      author: 'Tony Mendieta',
      position: 'LA Hydro-Jet',
      logo: laHydroJetLogo,
      logoAlt: null,
      featuredVideo: reviewSourcesMp4?.image?.file?.url,
      imageRight: false,
    },
  ];
};

export const videoContent = {
  quote: (
    <>
      With Marketing Pro, we can select our audience,
      <br /> select our campaign and it’s automatically sent.
      <br /> We’ve seen a great return on that.
    </>
  ),
  author: 'April Diemer, CFO',
  logo: tampaBayPlumbingLogo,
  logoAlt: 'Tampa Bay Plumbing logo',
  mainSrc: 'https://servicetitan-1.wistia.com/medias/0yurul958v',
};
