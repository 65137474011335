import React, { useEffect, useState, useRef } from 'react';
import { Col, Row } from 'components/Core/Grid';
import getVideoData from 'services/wistiaVideoService';
import Text from 'components/Core/Text';
import playButton from '../images/play-button-blue-outlined.svg';

import { Container, VideoCopy } from './styles';

const useFrameScale = (element) => {
  const [frameScale, setFrameScale] = useState({ width: 0, height: 0 });

  const handleResize = () => {
    if (element && element.clientWidth > 0) {
      setFrameScale({ width: element.clientWidth, height: element.clientHeight });
    }
  };

  useEffect(() => {
    handleResize(); // Initial resize
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [element]);

  useEffect(() => {
    if (element && !frameScale.width && element.clientWidth > 0) {
      setFrameScale({ width: element.clientWidth, height: element.clientHeight });
    }
  }, [element, frameScale]);

  return frameScale;
};

const MarketingProOverviewVideo = ({ author, mainSrc, quote, logo, logoAlt }) => {
  const [media, setMedia] = useState('');
  const [ratio, setRatio] = useState(1);
  const [video, setVideo] = useState();
  const [isPlaying, toggleIsPlaying] = useState(false);
  const containerRef = useRef(null);
  const frameScale = useFrameScale(containerRef.current);

  const mediaId = mainSrc.split('/').slice(-1)[0];

  const videoWidth = frameScale.width;
  const videoHeight = videoWidth / ratio;

  useEffect(() => {
    if (!document.getElementById('wistia_script')) {
      const wistiaScript = document.createElement('script');
      wistiaScript.id = 'wistia_script';
      wistiaScript.type = 'text/javascript';
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js';
      wistiaScript.async = true;
      document.body.appendChild(wistiaScript);
    }

    document.body.style.overflowX = 'hidden';

    return () => {
      document.body.style.overflowX = null;
    };
  }, []);

  useEffect(() => {
    const initVideo = async () => {
      if (mainSrc) {
        const data = await getVideoData(mainSrc);

        if (data) {
          setRatio(data.width / data.height);
          setMedia(mediaId);
          window._wq = window._wq || [];
          _wq.push({
            id: mediaId,
            onReady(_video) {
              console.log('I got a handle to the video!', setVideo(_video));
            },
          });
        }
      }
    };

    initVideo();
  }, [mainSrc]);

  const togglePlayVideo = () => {
    toggleIsPlaying(!isPlaying);
    if (video) {
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
    }
  };

  return (
    <Container ref={containerRef}>
      <Row type="flex" justify="center">
        <Col xs={20} xxl={18}>
          <div
            className={`wistia_embed wistia_async_${media} autoPlay=false videoFoam=true playerColor=ff0000 controlsVisibleOnLoad=false playButton=false`}
          />
          <VideoCopy isPlaying={isPlaying}>
            <div className="marketing-pro-overview-video--inner-content">
              <Text
                type="h4"
                fontSize="middle"
                color="white"
                className="marketing-pro-overview-video--title"
              >
                “{quote}”
              </Text>
              <div className="marketing-pro-overview-video--play-button">
                <Text type="p" color="white" size="medium" onClick={togglePlayVideo}>
                  Watch the customer story
                </Text>
                <img src={playButton} alt="play" />
              </div>
              <img src={logo} alt={logoAlt} />
              <Text
                type="p"
                color="white"
                size="medium"
                className="marketing-pro-overview-video--person"
              >
                {author}
              </Text>
            </div>
          </VideoCopy>
        </Col>
      </Row>
    </Container>
  );
};

export default MarketingProOverviewVideo;
