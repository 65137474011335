import React from 'react';
import { Parallax } from 'rc-scroll-anim';
import Text from 'components/Core/Text';

import { Container } from './styles';

const MarketingProValueProp = ({ backgroundImage }) => {
  return (
    <Container backgroundImage={backgroundImage?.image?.file?.url}>
      <Parallax
        animation={[
          { y: -150, playScale: [0.3, 1.2] },
          { opacity: 0.05, playScale: [-1, 0.1] },
        ]}
        style={{ opacity: 1, transform: 'translateY(0px)' }}
        className="marketing-pro-value--inner"
      ></Parallax>
      <Text type="h2" color="white">
        Marketing Pro customers have seen as high as 10% increases in close rates of unsold
        estimates.
      </Text>
    </Container>
  );
};

export default MarketingProValueProp;
