import styled from 'styled-components';

export const Copy = styled.div`
  background: ${(props) => props.backgroundUrl && `url(${props.backgroundUrl})`};

  .marketing-pro-row-content--icon {
    padding: 12px;
    border: 3px solid #02afff;
    border-radius: 50%;
    width: max-content;
    margin-bottom: 25px;
  }
  &:hover {
    cursor: pointer;
  }
  svg {
    stroke: white !important;
  }
`;

export const Container = styled.div`
  background: var(--darkest);
  padding: 60px 0;

  @media (max-width: 575px) {
    padding: 40px 0;
  }

  .marketing-pro-row-content--header {
    text-align: center;
    padding: 60px 0 120px 0;

    @media (max-width: 575px) {
      padding: 60px 0 80px 0;
    }
  }

  .marketing-pro-row-content--copy {
    display: flex;
    align-items: center;
  }

  .marketing-pro-row-content--copy,
  .marketing-pro-row-content--image {
    @media (max-width: 767px) {
      justify-content: center;
    }
    @media (max-width: 576px) {
      padding: 60px 0px 30px;
      .MuiGrid2-root {
        padding: 15px 28px;
      }
    }
  }

  .marketing-pro-row-content--right-side-image {
    position: absolute;
    right: -130px;
    top: -74px;
    z-index: 1;
  }

  .marketing-pro-row-content--left-side-image {
    position: absolute;
    left: -80px;
    top: -74px;
    z-index: 1;
  }

  .marketing-pro-feature-copy--hr {
    width: 100%;
    height: 1.5px;
    border-top: 1.5px solid var(--titan-blue-3);
    display: block;
  }

  .marketing-pro-feature-copy--quote {
    p {
      font-weight: var(--font-weight-300);
      margin-top: 15px;
    }
  }

  .marketing-pro-overview--target-img {
    @media (max-width: 767px) {
      width: 75%;
      margin: 0 auto;
    }
  }

  .marketing-pro-detail--customer-care-img {
    padding-right: 60px;

    @media (max-width: 991px) {
      padding: 40px;
    }

    @media (max-width: 767px) {
      padding: 0px;
    }
  }

  .marketing-pro-detail--large-image {
    @media (min-width: 1600px) {
      width: 60%;
    }
  }
`;

export const InnerContainer = styled.div`
  max-width: 1196px;
  padding: 0 28px;
  margin: 0 auto;

  @media (max-width: 575px) {
    display: flex;
    justify-content: center;
  }
`;
