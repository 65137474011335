import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import WebsiteImage from 'components/WebsiteImage';
import FeatureCarouselCopy from './FeatureCarouselCopy';
import FeatureCopy from './FeatureCopy';
import Text from 'components/Core/Text';

import { Container, InnerContainer } from './styles';

const MarketingProRowContent = (props) => {
  const {
    featuredImageAlt,
    featuredImageUrl,
    featuredVideo,
    header,
    type,
    colClassName,
    imageRight,
    featuredSideImageRight,
    featuredSideImageLeft,
  } = props;

  return (
    <Container>
      <InnerContainer>
        {header && (
          <Row type="flex" justify="center">
            <Col xs={22} sm={20} xl={18} xxl={16}>
              <Text type="h2" color="white" className="marketing-pro-row-content--header">
                {header}
              </Text>
            </Col>
          </Row>
        )}
        <Row
          type="flex"
          justify="center"
          spacing={12}
          direction={imageRight ? 'row-reverse' : 'row'}
          className="marketing-pro-row-content--image"
        >
          <Col xs={22} md={imageRight ? 13 : 12} className={colClassName}>
            {featuredSideImageLeft && (
              <WebsiteImage
                image={featuredSideImageLeft}
                alt={featuredImageAlt}
                className="marketing-pro-row-content--left-side-image"
              />
            )}
            {featuredVideo ? (
              <>
                <video loop width="100%" id="video" autoPlay muted playsInline>
                  <source src={featuredVideo} type="video/mp4" />
                  Your browser does not support HTML video.
                </video>
              </>
            ) : (
              <WebsiteImage image={featuredImageUrl} alt={featuredImageAlt} />
            )}
            {featuredSideImageRight && (
              <WebsiteImage
                image={featuredSideImageRight}
                alt={featuredImageAlt}
                className="marketing-pro-row-content--right-side-image"
              />
            )}
          </Col>
          <Col xs={22} md={imageRight ? 10 : 11} className="marketing-pro-row-content--copy">
            {type === 'carousel' && <FeatureCarouselCopy {...props} />}
            {type === 'feature' && <FeatureCopy {...props} />}
          </Col>
        </Row>
      </InnerContainer>
    </Container>
  );
};

export default MarketingProRowContent;
