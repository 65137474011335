import React, { useState } from 'react';
import { Parallax } from 'rc-scroll-anim';
import TrackVisibility from 'react-on-screen';
import { Slider } from '@mui/material';
import useIsClient from 'hooks/useIsClient';
import heroVideo from '../../videos/marketingProOverviewHeroVideo.mp4';
import mutedBtn from '../../images/volume-muted.svg';
import unmutedBtn from '../../images/volume-unmuted.svg';
import videoThumbnail from '../../images/marketing-pro-video-thumbnail.png';

import { Container, UnMuteToolTip, VideoContainer } from './styles';

const MarketingProHeroHTML5Video = () => {
  const [playingVideo, setPlayingVideo] = useState(false);
  const [isMuted, toggleIsMuted] = useState(true);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const [prevSliderValue, setPrevSliderValue] = useState(null);
  const [initialUnMute, setInitialUnMute] = useState(false);
  const isClient = useIsClient();

  const onSliderChange = (e, value) => {
    const video = document.getElementById('video');
    const volumeLevel = value / 100;
    video.volume = volumeLevel;
    video.muted = false;
    setSliderValue(value);
  };

  const playVideo = () => {
    setPlayingVideo(true);
    const video = document.getElementById('video');

    if (video) {
      video.play();
    }
  };

  const toggleMuted = () => {
    if (!initialUnMute) {
      initialUnmute();
    } else {
      afterInitialUnmute();
    }
  };

  const afterInitialUnmute = () => {
    const video = document.getElementById('video');

    toggleIsMuted(!isMuted);

    if (isMuted) {
      setPrevSliderValue(sliderValue);
      setSliderValue(0);
      video.muted = true;
    } else {
      setSliderValue(prevSliderValue);
      video.muted = false;
    }
  };

  const initialUnmute = () => {
    const video = document.getElementById('video');

    video.muted = false;
    video.volume = 0.5;
    setSliderValue(50);
    setInitialUnMute(true);
  };

  const stopVideo = () => {
    const video = document.getElementById('video');

    if (video) {
      video.pause();
    }
  };

  const loadVideo = () => {
    setVideoLoaded(true);
  };

  return (
    <Container tooltip={initialUnMute}>
      <Parallax
        className="min-md"
        animation={[
          { playScale: [0.2, 1], onStart: () => loadVideo() },
          {
            width: '100%',
            playScale: [-0.7, 0],
            onStart: () => playVideo(),
            onCompleteBack: () => stopVideo(),
          },
          { playScale: [0.75, 1], onStart: () => stopVideo(), onCompleteBack: () => playVideo() },
        ]}
        style={{ width: '60%', position: 'relative' }}
      >
        <div className="marketing-pro-hero--volume-controls">
          {initialUnMute && (
            <Slider
              orientation="vertical"
              defaultValue={50}
              value={sliderValue}
              valueLabelDisplay="auto"
              onChange={onSliderChange}
              className="marketing-pro-hero--video-slider"
            />
          )}
          <UnMuteToolTip hide={initialUnMute}>Tap to Unmute</UnMuteToolTip>
          <img
            src={isMuted ? unmutedBtn : mutedBtn}
            className="mute-unmute-btn"
            onClick={() => toggleMuted()}
          />
        </div>
        <VideoContainer playingVideo={playingVideo}>
          {videoLoaded && (
            <video
              playsInline
              loop
              muted
              width="100%"
              id="video"
              style={{ display: playingVideo ? 'block' : 'none' }}
            >
              <source src={heroVideo} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          )}
        </VideoContainer>
      </Parallax>

      {isClient && (
        <TrackVisibility>
          {({ isVisible }) => {
            const video = document.getElementById('video_mobile');
            if (isVisible) {
              video?.play();
            } else {
              video?.pause();
            }

            return (
              <video
                className="max-md"
                playsInline
                loop
                controls
                muted
                width="100%"
                id="video_mobile"
                poster={videoThumbnail}
              >
                <source src={heroVideo} type="video/mp4" />
                Your browser does not support HTML video.
              </video>
            );
          }}
        </TrackVisibility>
      )}
    </Container>
  );
};

export default MarketingProHeroHTML5Video;
