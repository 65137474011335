import React from 'react';
import WebsiteImage from 'components/WebsiteImage';

const MarketingProRowContentCarouselImage = ({ active, image, isMobile, isVisible, last }) => {
  const getClass = () => {
    if (last) {
      if (active && !isVisible && !isMobile) {
        return '';
      } else if (!isVisible) {
        return 'leaving';
      } else {
        return active ? 'entering' : 'leaving';
      }
    }
    return active ? 'entering' : 'leaving';
  };

  return (
    <WebsiteImage
      image={image?.src}
      alt={image?.alt}
      className={`marketing-pro-features-carousel--image ${getClass()}`}
    />
  );
};

export default MarketingProRowContentCarouselImage;
