import styled from 'styled-components';

const Container = styled.div`
  display: grid;
  justify-items: center;

  .marketing-pro-hero--volume-controls {
    position: absolute;
    bottom: 50px;
    left: 50px;
    width: 40px;
    z-index: 2;
    display: flex;
    flex-direction: ${({ tooltip }) => (tooltip ? 'column' : 'column-reverse')};
    height: 200px;

    @media (max-width: 575px) {
      bottom: 30px !important;
      right: 30px !important;
    }
  }

  .marketing-pro-hero--video-slider {
    height: 150px;
    align-self: center;
  }

  .mute-unmute-btn {
    color: var(--white);
    height: 40px;
    width: 40px;
    margin-top: 20px;
    border-radius: 50%;
    display: flex;
    align-self: center;
    justify-content: center;
    z-index: 999;

    &:hover {
      cursor: pointer;
    }
  }

  video {
    background: #1b1b1b;
    position: relative;
    z-index: 1;
  }
`;

const UnMuteToolTip = styled.div`
  background: var(--white);
  padding: 10px;
  width: 75px;
  margin-left: -20px;
  border-radius: 3px;
  color: var(--darkest);
  font-weight: bold;
  z-index: 3;
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  text-align: center;
  position: absolute;
  bottom: 58px;
  border: 1px solid var(--grey-4);

  &:after,
  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    left: 37%;
  }

  &:after {
    bottom: -20px;
    border-color: var(--white) transparent transparent transparent;
    border-width: 10px;
    left: 28px;
  }

  &:before {
    bottom: -22px;
    border-color: var(--grey-4) transparent transparent transparent;
    border-width: 11px;
  }
`;

const VideoContainer = styled.div`
  width: 100%;
  background-size: cover;
  background-color: var(--darkest);
  height: ${({ playingVideo }) => (playingVideo ? 'auto' : '50vh')};
`;

export { Container, UnMuteToolTip, VideoContainer };
