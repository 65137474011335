import styled from 'styled-components';
import { SectionLayout } from 'components/Sections/SectionLayout';

const Container = styled(SectionLayout)`
  background: var(--darkest);
  .marketing-pro-overview--lottie {
    position: relative;
    width: calc(100% + 115px);
    margin: 50px 0 50px -115px;

    &:before {
      position: absolute;
      content: '';
      height: 15%;
      width: 15%;
      background: linear-gradient(
        to bottom,
        rgba(34, 34, 34, 1) 0%,
        rgba(34, 34, 34, 1) 28%,
        rgba(34, 34, 34, 0.56) 66%,
        rgba(34, 34, 34, 0.16) 100%
      );
      top: 0px;
      z-index: 2;
      right: 0;
    }
    &:after {
      position: absolute;
      content: '';
      height: 50px;
      width: 60px;
      background: linear-gradient(
        to right,
        rgb(34, 34, 34) 0%,
        rgba(34, 34, 34, 0.55) 53%,
        rgba(34, 34, 34, 0) 100%
      );
      left: 0;
      bottom: 0;
      z-index: 2;
    }

    @media (max-width: 575px) {
      width: calc(100% + 50px);
      margin-left: -30px;
    }
  }
`;

export { Container };
