import styled from 'styled-components';

export const Container = styled.div`
  background: var(--darkest);
  & > div {
    margin: 0 !important;
  }
  @media (min-width: 992px) {
    padding-bottom: 105px;
  }

  img {
    width: 100%;
  }

  .marketing-pro-features-carousel--inner-container {
    display: block;
    position: relative;
  }

  .marketing-pro-features-carousel--copy-container {
    display: inline-block;
  }

  .marketing-pro-features-carousel--image-section {
    position: -webkit-sticky;
    position: sticky;
    top: 200px;
    float: left;
  }

  .marketing-pro-features-carousel--image {
    -webkit-transition: all 0.5s linear;
    transition: opacity 0.9s linear, display 0 linear 2s;
    opacity: 1;
    display: block;
  }

  .marketing-pro-features-carousel--image.entering {
    display: block;
    opacity: 1;
  }

  .marketing-pro-features-carousel--image.leaving {
    display: none;
    opacity: 0;
  }
`;
