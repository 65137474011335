import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--darkest);
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    background: var(--darkest);
    clip-path: polygon(0 100%, 100% 11%, 100% -1%, -1% -1%);
    height: 160px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  h2 {
    align-self: center;
    color: var(--white);
    font-size: var(--font-size-35);
    font-weight: var(--font-weight-700);
    margin: 0 auto;
    max-width: 800px;
    padding: 0 60px;
    position: absolute;
    text-align: center;
  }

  .marketing-pro-value--inner {
    background-image: ${(props) => `url("${props.backgroundImage}")`};
    background-size: cover;
    color: white;
    height: 750px;
    width: 100%;
  }

  @media (max-width: 767px) {
    height: 520px;

    h2 {
      font-size: var(--font-size-h4);
      padding: 0 30px;
    }

    .marketing-pro-value--inner {
      background-size: contain;
      opacity: 0.1 !important;
      height: 540px;
    }
  }
`;

export { Container };
