import React, { useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import {
  featureCarouselContent,
  overviewCopyImageSectionOne,
  overviewCopyImageSectionTwo,
  heroContent,
  videoContent,
} from 'components/Page/MarketingPro/data/marketingProOverviewData';
import { subNavData } from 'components/Page/MarketingPro/data/subNavData';
import { globalHistory } from '@reach/router';
import SEO, { createSEO } from 'components/Core/SEO';
import WebsiteLayout from 'layouts/websiteLayout';
import ModalMarketingForm from 'components/Form/ModalMarketingForm';
import SubNav from 'components/Page/MarketingPro/MarketingProSubNav';
import MarketingProHero from 'components/Page/MarketingPro/MarketingProHero';
import MarketingProRowContent from 'components/Page/MarketingPro/MarketingProRowContent';
import MarketingProRowContentCarousel from 'components/Page/MarketingPro/MarketingProRowContentCarousel';
import MarketingProOverviewVideo from 'components/Page/MarketingPro/MarketingProOverviewVideo';
import MarketingProValueProp from 'components/Page/MarketingPro/MarketingProValueProp';
import MarketingProOverviewStats from 'components/Page/MarketingPro/MarketingProOverviewStats';
import ContentfulRowContent from 'components/Contentful/ContentfulRowContent';
import useMarketingProOverviewData from 'components/Page/MarketingPro/hooks/useMarketingProOverviewData';
import useGenerateBreadcrumbs from 'utils/getBreadcrumbData';
import Breadcrumbs from 'components/Core/Breadcrumbs';
import MarketingProPageContainer from 'components/Page/MarketingPro/styles';

const MarketingProOverview = (props) => {
  const {
    bottomForm,
    hero,
    heroInlineMarketingForm,
    modalInlineMarketingForm,
    seo,
    statsGraph,
    valuePropBackground,
  } = useMarketingProOverviewData();
  const pageSeo = createSEO(seo, props);
  const [isClient, setIsClient] = useState(false);
  const [isAnchorModalOpen, toggleIsAnchorModalOpen] = useState(false);
  const featureCarouselContentData = featureCarouselContent();
  const overviewCopyImageSectionOneData = overviewCopyImageSectionOne();
  const overviewCopyImageSectionTwoData = overviewCopyImageSectionTwo();
  const heroImg = props.data.contentfulImage;
  const bottomFormUpdatedAnchor = { ...bottomForm, anchorId: `${bottomForm.anchorId}_id` };

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    const { hash } = globalHistory.location;
    const anchorIdModal = `#${bottomForm.anchorId}`;

    if (hash === anchorIdModal) {
      toggleIsAnchorModalOpen(true);
    }
  }, []);

  const breadcrumbs = useGenerateBreadcrumbs(seo, hero);

  const renderSubNav = () => (
    <SubNav data={subNavData} form={props.data.contentfulInlineMarketingForm} />
  );

  return (
    <MarketingProPageContainer>
      <WebsiteLayout layout="marketingPro" SubNav={renderSubNav}>
        <SEO {...pageSeo} />
        <Breadcrumbs
          breadcrumbs={breadcrumbs.data}
          absoluteMode={breadcrumbs.absolute}
          layout="marketingpro"
        />

        <ModalMarketingForm
          isModalOpen={isAnchorModalOpen}
          onModalCancel={() => toggleIsAnchorModalOpen(false)}
          inlineMarketingForm={modalInlineMarketingForm}
          onFormSuccess={() => toggleIsAnchorModalOpen(false)}
        />

        <MarketingProHero
          form={heroInlineMarketingForm}
          type="video-bottom"
          splashPage
          backgroundImg={heroImg}
          {...heroContent}
        />

        <MarketingProOverviewStats image={statsGraph.image} />
        {isClient && (
          <div className="max-lg">
            {featureCarouselContentData.map((rowContent, index) => {
              return (
                <MarketingProRowContent
                  key={`row${index}`}
                  type="carousel"
                  backgroundColor="var(--darkest)"
                  {...rowContent}
                />
              );
            })}
          </div>
        )}
        <div className="min-lg">
          <MarketingProRowContentCarousel
            data={featureCarouselContentData}
            inlineMarketingForm={heroInlineMarketingForm}
            backgroundColor="var(--darkest)"
          />
        </div>
        <MarketingProValueProp backgroundImage={valuePropBackground} />
        {overviewCopyImageSectionOneData.map((rowContent, index) => {
          return <MarketingProRowContent key={`row${index}`} type="feature" {...rowContent} />;
        })}

        <MarketingProOverviewVideo {...videoContent} />
        <RowContentBottom>
          {overviewCopyImageSectionTwoData.map((rowContent, index) => {
            return (
              <MarketingProRowContent
                key={`row${index}`}
                type="feature"
                backgroundColor="var(--darkest)"
                {...rowContent}
              />
            );
          })}
        </RowContentBottom>
        <RowContentCTA>
          <ContentfulRowContent {...bottomFormUpdatedAnchor} />
        </RowContentCTA>
      </WebsiteLayout>
    </MarketingProPageContainer>
  );
};

export default MarketingProOverview;

export const MarketingProPageQuery = graphql`
  query MarketingProPageQuery {
    contentfulInlineMarketingForm(contentful_id: { eq: "6DAZfo8POZVhudazUjAIxd" }) {
      ...InlineMarketingForm
    }
    contentfulImage(contentful_id: { eq: "3GhdYUBXQ7mxRCBRKKJH08" }) {
      ...WebsiteImage
    }
  }
`;

const RowContentBottom = styled.div`
  padding: 40px 0 120px 0;
  background: var(--darkest);
  overflow: hidden;

  @media (max-width: 575px) {
    padding: 40px 0 0 0;
  }
`;

const RowContentCTA = styled.div`
  @media (max-width: 575px) {
    h2 {
      max-width: 275px;
      margin: 0;
    }
    .rich-text--container {
      display: flex;
      align-items: flex-start;
    }
  }
`;
