import React, { useState } from 'react';
import HyperLink from 'components/Core/HyperLink';
import WebsiteImage from 'components/WebsiteImage';
import { ArrowRightIcon, ArrowRightHoverIcon } from 'components/Brand/Icons';
import Text from 'components/Core/Text';

import { Copy, CopyContainer } from './styles';

const MarketingProRowContentCarouselCopy = ({
  active,
  first,
  forwardedRef,
  iconUrl,
  iconAlt,
  isMobile,
  isVisible,
  last,
  linkUrl,
  title,
  description,
}) => {
  const [cardHover, setCardHover] = useState(false);

  const getClass = () => {
    if (isMobile && isVisible) {
      return active ? 'entering spacer' : 'leaving spacer';
    } else if (last) {
      if (active && !isVisible && !isMobile) {
        return 'slide-right';
      } else {
        return active && isVisible ? 'entering' : 'leaving';
      }
    }
    return active ? 'entering' : 'leaving';
  };

  return (
    <CopyContainer ref={forwardedRef} isMobile={isMobile} first={first} last={last}>
      <HyperLink href={linkUrl}>
        <Copy
          onMouseEnter={() => setCardHover(true)}
          onMouseLeave={() => setCardHover(false)}
          className={`marketing-pro-features-carousel--copy-section ${getClass()}`}
        >
          <div className="marketing-pro-row-content--icon">
            <WebsiteImage image={iconUrl} alt={iconAlt} />
          </div>
          <div>
            <Text type="h5" color="white">
              {title}
            </Text>
            {description && (
              <Text type="p" size="medium" color="white">
                {description}
              </Text>
            )}
          </div>
          <Text type="p" size="medium" color="white" marginTop="50px">
            Learn more {!cardHover ? <ArrowRightIcon /> : <ArrowRightHoverIcon />}
          </Text>
        </Copy>
      </HyperLink>
    </CopyContainer>
  );
};

export default MarketingProRowContentCarouselCopy;
