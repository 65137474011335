import styled from 'styled-components';

const Container = styled.div`
  background: transparent;
  height: 60px;
  top: ${({ absoluteMode, simpleHeader, stickyWithoutHeader, subNav, promoHeight }) => {
    if (absoluteMode) {
      return `calc(${
        simpleHeader ? '132px' : !subNav ? '180px' : stickyWithoutHeader ? '147px' : '195px'
      } + ${promoHeight}px)`;
    }
    return 'auto';
  }};

  ${({ absoluteMode }) => {
    return absoluteMode
      ? `
        position: absolute;
        width: 100%;
        z-index: 100;
      `
      : '';
  }};

  .breadcrumb--arrow {
    margin-left: 8px;
    margin-bottom: -2px;
  }
  .breadcrumb--icon {
    margin-left: 10px;
  }

  .breadcrumb--arrow.reverse {
    transform: scaleX(-1);
    margin-left: 0;
    margin-right: 10px;
  }

  @media (max-width: 1450px) {
    top: ${({ absoluteMode, simpleHeader, layout, promoHeight }) =>
      `${
        layout === 'toolbox'
          ? 'inherit'
          : absoluteMode && simpleHeader
          ? `calc(122px + ${promoHeight}px)`
          : absoluteMode
          ? `calc(150px + ${promoHeight}px)`
          : 'inherit'
      }`};
    margin-top: ${({ simpleHeader }) => simpleHeader && '0px'};
  }

  @media (max-width: 1199px) {
    top: ${({ absoluteMode, simpleHeader, layout, promoHeight }) =>
      `${
        layout === 'toolbox'
          ? 'inherit'
          : absoluteMode && simpleHeader
          ? `calc(122px + ${promoHeight}px)`
          : absoluteMode
          ? `calc(100px + ${promoHeight}px)`
          : 'inherit'
      }`};
  }

  @media (max-width: 575px) {
    height: 68px;

    .breadcrumb--icon {
      width: 17px;
    }
  }
`;

const ChildLink = styled.p`
  color: ${({ darkTheme }) => (darkTheme ? 'var(--darkest)' : 'var(--white)')} !important;
  font-size: var(--font-size-16) !important;
  margin: 0;
  display: flex;
`;

const ParentLink = styled.p`
  color: ${({ darkTheme }) => (darkTheme ? 'var(--grey-8)' : 'var(--white)')} !important;
  font-size: var(--font-size-16);
  margin: 0 8px 0 0;
  display: flex;
  @media (max-width: 575px) {
    font-size: var(--font-size-16);
    display: flex;
    align-items: center;
  }
  @media (max-width: 320px) {
    font-size: var(--font-size-13);
  }
`;

const InnerContainer = styled.div`
  max-width: var(--max-width);
  margin: 0px auto;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;

  padding: 0 20px;

  .breadcrumb--links-wrapper {
    display: flex;
  }
`;

export { ChildLink, Container, InnerContainer, ParentLink };
