import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  background: var(--darkest);
  overflow: hidden;
  padding: 60px 0 40px 0;

  @media (max-width: 575px) {
    padding: 20px 0;
  }
`;

const VideoCopy = styled.div`
  position: absolute;
  top: 0;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  display: ${(props) => (props.isPlaying ? 'none' : 'flex')};
  width: 100%;
  height: 100%;

  img {
    margin-top: 8px;
  }

  .marketing-pro-overview-video--play-button {
    display: flex;
    justify-content: center;
    p {
      width: fit-content;
      height: fit-content;
      margin: auto 0;
      text-decoration: underline;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .marketing-pro-overview-video--inner-content {
    align-self: center;
    margin: 0 auto;
  }

  .marketing-pro-overview-video--title {
    line-height: var(--line-height-130);
    @media (max-width: 767px) {
      display: none;
    }
  }

  .marketing-pro-overview-video--play-button {
    @media (max-width: 575px) {
      p {
        font-size: var(--font-size-16);
      }
    }
  }

  .marketing-pro-overview-video--person {
    font-weight: var(--font-weight-300);

    @media (max-width: 575px) {
      margin: 0 0 10px 0;
      font-size: var(--font-size-16);
    }
  }
`;

export { Container, VideoCopy };
