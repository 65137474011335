import styled from 'styled-components';

const MarketingProPageContainer = styled.div`
  .header-container {
    .website-header {
      &.shrink-nav.scroll-down {
        .website-header--main-menu {
          max-height: 0;
          overflow: hidden;
        }
      }

      &.mobile-menu-opened {
        .toolbox-sticky-subnav {
          display: none;
        }
      }
    }
  }
  main {
    padding-top: 0;
  }

  .breadcrumb--wrapper {
    top: 252px;

    @media (max-width: 1450px) {
      top: 218px;
    }

    @media (max-width: 1199px) {
      top: 154px;
    }
  }
`;

export default MarketingProPageContainer;
