import React from 'react';
import WebsiteImage from 'components/WebsiteImage';

import { Container } from './styles';

const MarketingProOverviewStats = ({ image }) => (
  <Container>
    <WebsiteImage image={image} alt="Marketing Pro - Stats graph" />
  </Container>
);

export default MarketingProOverviewStats;
